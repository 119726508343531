.clock-item {
 background: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.05) 0%,
  rgba(255, 255, 255, 0) 73.86%
 );
 -webkit-backdrop-filter: blur(4px);
 backdrop-filter: blur(4px);
 border-radius: 16px;
 position: relative;
 padding: 23px 0;
 display: flex;
 width: 85px;
 justify-content: center;
 align-items: center;
}
.clock-item .step {
 -webkit-backdrop-filter: blur(4px);
 backdrop-filter: blur(4px);
 border-radius: 100px;
 padding: 6px 5px;
 background: linear-gradient(25deg, #2600fc, #ff00ea), #ffffff;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 border: 1px solid rgba(255, 255, 255, 0.1);
 font-weight: 600;
 font-size: 12px;
 font-family: "Conthrax";
}
