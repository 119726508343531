.sandbox {
 background: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.05) 0%,
  rgba(255, 255, 255, 0) 73.86%
 );
 -webkit-backdrop-filter: blur(4px);
 backdrop-filter: blur(4px);
 border-radius: 16px;
 border: 1px solid rgba(255, 255, 255, 0.1);
 padding: 37px 30px;
}
