/**
  * Name: Thermal - NFT Portfolio and Landing Page HTML Template
  * Version: 1.0.3
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/
@use "./abstracts/" as *;

// @import '../bootstrap/css/bootstrap.min.css';

@import url("https://fonts.cdnfonts.com/css/conthrax");
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

body {
 font-family: "Readex Pro", sans-serif;
 font-size: 14px;
 font-weight: 300;
 line-height: 1.4;
 color: $text-color;
 background: #0f051d;
 position: relative;
 overflow-x: hidden;
 color: #ffffff;
}
:root {
 --primary-color: #5c27fe;
}

ul,
li {
 list-style-type: none;
 margin: 0;
 padding: 0;
}
p {
 margin-bottom: 0;
}

a,
button {
 font-family: "Conthrax", sans-serif;
 font-size: 15px;
 text-decoration: none;
 color: unset;
 transition: all 0.5s ease-in-out;
 cursor: pointer;
 display: inline-block;
 color: $white;
 &:hover {
  transition: all 0.3s;
  color: var(--primary-color);
 }
}
.boild {
 font-weight: 700;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
 font-family: "Conthrax", sans-serif;
 font-weight: 700;
 line-height: 1.2;
 color: $white;
 margin-bottom: 0;
}

h2,
.h2 {
 font-size: 60px;

 @include mobile {
  font-size: 40px;
 }
}
h3,
.h3 {
 font-size: 50px;

 @include tablet {
  font-size: 30px;
 }
}
h4,
.h4 {
 font-size: 40px;
 @include mobile {
  font-size: 30px;
 }
}
h5,
.h5 {
 font-size: 20px;
}
h6,
.h6 {
 font-size: 15px;
}
.fs-14 {
 font-size: 14px;
}
.fs-16 {
 font-size: 16px;
}
.fs-17 {
 font-size: 17px;
}
.fs-18 {
 font-size: 18px;
 line-height: 1.7;
}
.fs-20 {
 font-size: 20px;
}
.fs-21 {
 font-size: 21px;
}
.fs-24 {
 font-size: 24px;
}
.fs-30 {
 font-size: 30px;
}
.fs-36 {
 font-size: 36px;
}
.fs-42 {
 font-size: 42px;
}
.mr-0 {
 margin-right: 0;
}
.pt-128 {
 padding-top: 128px;
}
.mt--16 {
 margin-top: -16px;
}
.mt-15 {
 margin-top: 15px;
}
.mt-17 {
 margin-top: 17px;
}
.mb--30 {
 margin-bottom: -30px;
}
.mt--23 {
 margin-top: -23px;
}
.mt-5 {
 margin-top: 5px !important;
}
.mt-9 {
 margin-top: 9px;
}
.mt-12 {
 margin-top: 12px;
}
.mt-16 {
 margin-top: 16px !important;
}
.mt-19 {
 margin-top: 19px;
}
.mt-21 {
 margin-top: 21px;
}
.mt-24 {
 margin-top: 24px;
}
.mt-25 {
 margin-top: 25px;
}
.mt-27 {
 margin-top: 27px;
}
.mt-28 {
 margin-top: 28px;
}
.mt-29 {
 margin-top: 29px;
}
.mt-37 {
 margin-top: 37px;
}
.mt-42 {
 margin-top: 42px;
}
.mt-48 {
 margin-top: 48px;
}
.mt-45 {
 margin-top: 45px;
}
.mt-50 {
 margin-top: 50px;
}
.mt-51 {
 margin-top: 51px;
}
.mt-52 {
 margin-top: 52px;
}
.mt-53 {
 margin-top: 53px;
}
.mt-61 {
 margin-top: 61px;
}
.mt-67 {
 margin-top: 67px;
}
.mt-104 {
 margin-top: 104px;
}
.mt-107 {
 margin-top: 107px;
}
.mt-200 {
 margin-top: 200px;
}
.mt-223 {
 margin-top: 223px;
}
.mb-0 {
 margin-bottom: 0;
}

.pd-0 {
 padding: 0 !important;
}
.mb-6 {
 margin-bottom: 6px !important;
}
.mb-7 {
 margin-bottom: 7px;
}
.mb-10 {
 margin-bottom: 10px !important;
}
.mb-13 {
 margin-bottom: 13px !important;
}
.mb-14 {
 margin-bottom: 14px !important;
}
.mb-15 {
 margin-bottom: 15px !important;
}
.mb-17 {
 margin-bottom: 17px !important;
}
.mb-19 {
 margin-bottom: 19px !important;
}
.mb-20 {
 margin-bottom: 20px !important;
}
.mb-26 {
 margin-bottom: 26px !important;
}
.mb-28 {
 margin-bottom: 28px !important;
}
.mb-29 {
 margin-bottom: 29px !important;
}
.mb-30 {
 margin-bottom: 30px !important;
}
.mb-31 {
 margin-bottom: 31px !important;
}
.mb-33 {
 margin-bottom: 33px !important;
}
.mb-34 {
 margin-bottom: 34px !important;
}
.mb-37 {
 margin-bottom: 37px !important;
}
.mb-41 {
 margin-bottom: 41px !important;
}
.mb-43 {
 margin-bottom: 43px !important;
}
.mb-46 {
 margin-bottom: 46px !important;
}
.mb-50 {
 margin-bottom: 50px !important;
}
.mb-53 {
 margin-bottom: 53px !important;
}
.mb-58 {
 margin-bottom: 58px !important;
}
.mr-28 {
 margin-right: 28px !important;
}
.pt-12 {
 padding-top: 12px !important;
}
.pt-24 {
 padding-top: 24px !important;
}
.pd-0 {
 @include tablet {
  padding: 0;
 }
}
.pb-team {
 padding: 0 150px;
 margin-top: 38px;

 @include tablet {
  padding: 0;
  margin-top: 0;
 }
}

.line-h17 {
 line-height: 1.7;
}
.container {
 max-width: 1140px;
 width: 100%;
 margin: auto;
}

.container.big {
 max-width: 1650px;
}

.row > * {
 padding-left: 15px;
 padding-right: 15px;
}

.wrapper {
 height: 100%;
 overflow: hidden;
 width: 100%;
 position: relative;

 @include desktop-1200 {
  background: none !important;
 }
}

.rev {
 @include desktop-1200 {
  flex-direction: column-reverse;
 }
}

.center {
 text-align: center;
}

.bg-1 {
 background: #130f40;
}

.bg-2 {
 background: #010f33;
}
@keyframes move5 {
 0% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
 }
 50% {
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
 }
 100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
 }
}

.mouse-cursor {
 position: fixed;
 left: 0;
 top: 0;
 pointer-events: none;
 border-radius: 50%;
 -webkit-transform: translateZ(0);
 transform: translateZ(0);
 visibility: hidden;
}
.cursor-inner {
 margin-left: -3px;
 margin-top: -3px;
 width: 6px;
 height: 6px;
 z-index: 100;
 background-color: var(--primary-color);
 -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
  margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
 transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
  margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.cursor-inner.cursor-hover {
 margin-left: -20px;
 margin-top: -20px;
 width: 40px;
 height: 40px;
 background-color: var(--primary-color);
 opacity: 0.3;
}
.cursor-outer {
 margin-left: -15px;
 margin-top: -15px;
 width: 30px;
 height: 30px;
 border: 2px solid var(--primary-color);
 -webkit-box-sizing: border-box;
 box-sizing: border-box;
 z-index: 100;
 opacity: 0.5;
 -webkit-transition: all 0.08s ease-out;
 transition: all 0.08s ease-out;
}
.cursor-outer.cursor-hover {
 opacity: 0;
}
body[data-magic-cursor="hide"] .mouse-cursor {
 display: none;
 opacity: 0;
 visibility: hidden;
 position: absolute;
 z-index: -1111;
}

// Animation
@keyframes animate-1 {
 0%,
 100% {
  transform: translateY(0vh) translateX(0vmin);
 }
 50% {
  transform: translateY(-1.5vh) translateX(5vmin);
 }
}

@keyframes animate-2 {
 0%,
 100% {
  transform: translateY(0px);
 }
 50% {
  transform: translateY(-15vmin);
 }
}
@keyframes animate-3 {
 0%,
 100% {
  transform: translateY(0vh);
 }
 50% {
  transform: translateY(-5vmin);
 }
}

@keyframes animate-4 {
 0%,
 100% {
  transform: translateY(0vh);
 }
 50% {
  transform: translateY(10vmin);
 }
}

@keyframes animate-5 {
 0%,
 100% {
  transform: translateY(0vh) translateX(0vmin);
 }
 50% {
  transform: translateY(-1.5vh) translateX(5vmin);
 }
}

.shape {
 content: "";
 position: absolute;
 width: 2000px;
 height: 2000px;
 top: -70%;
 left: -60%;
 z-index: -1;
 background: radial-gradient(
   34.98% 34.98% at 50% 50%,
   #725bda 0%,
   rgba(6, 19, 45, 0) 100%
  )
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
 opacity: 0.5;

 &.right {
  left: auto;
  right: -60%;
 }

 &.s3 {
  z-index: -2;
  width: 800px;
  height: 800px;
  background: linear-gradient(239.67deg, #0057ff -10.29%, #7ff805 197.14%);
  opacity: 0.6;
  mix-blend-mode: normal;
  filter: blur(150px);
  left: auto !important;
  top: 15% !important;
  right: -20%;
 }
}

/* ====================== [Start Text Animation] ====================== */

span,
a {
 display: inline-block;
 text-decoration: none;
}

.splitting.animated .char {
 -webkit-animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
 animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
 -webkit-animation-delay: calc(30ms * var(--char-index));
 animation-delay: calc(30ms * var(--char-index));
}

.splitting .whitespace {
 width: 9px;
}

@-webkit-keyframes fadeInUp {
 0% {
  opacity: 0;
  -webkit-transform: translateY(50px);
  transform: translateY(30px);
 }
 100% {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
 }
}

@keyframes fadeInUp {
 0% {
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
 }
 100% {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
 }
}

.splitting.text.animated .char {
 -webkit-animation: fadeIn 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
 animation: fadeIn 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
 -webkit-animation-delay: calc(10ms * var(--char-index));
 animation-delay: calc(10ms * var(--char-index));
}

.splitting.text .whitespace {
 width: 4px;
}

@-webkit-keyframes fadeIn {
 0% {
  opacity: 0;
 }
 100% {
  opacity: 1;
 }
}

@keyframes fadeIn {
 0% {
  opacity: 0;
 }
 100% {
  opacity: 1;
 }
}

.imgio {
 position: relative;
}

.imgio .cimgio {
 position: absolute;
 height: 100%;
 width: 100%;
 bottom: 0;
 right: 0;
 background: #fff;
 visibility: visible !important;
 -webkit-transition: all 0.5s linear;
 -o-transition: all 0.5s linear;
 transition: all 0.5s linear;
 -webkit-transition-delay: 0.2s;
 -o-transition-delay: 0.2s;
 transition-delay: 0.2s;
}

.imgio .animated {
 height: 0;
}

.cimgio[data-delay="100"] {
 -webkit-transition-delay: 0.1s;
 -o-transition-delay: 0.1s;
 transition-delay: 0.1s;
}

.cimgio[data-delay="200"] {
 -webkit-transition-delay: 0.2s;
 -o-transition-delay: 0.2s;
 transition-delay: 0.2s;
}

.cimgio[data-delay="300"] {
 -webkit-transition-delay: 0.3s;
 -o-transition-delay: 0.3s;
 transition-delay: 0.3s;
}

.cimgio[data-delay="400"] {
 -webkit-transition-delay: 0.4s;
 -o-transition-delay: 0.4s;
 transition-delay: 0.4s;
}

.cimgio[data-delay="500"] {
 -webkit-transition-delay: 0.5s;
 -o-transition-delay: 0.5s;
 transition-delay: 0.5s;
}

.cimgio[data-delay="600"] {
 -webkit-transition-delay: 0.6s;
 -o-transition-delay: 0.6s;
 transition-delay: 0.6s;
}

.cimgio[data-delay="700"] {
 -webkit-transition-delay: 0.7s;
 -o-transition-delay: 0.7s;
 transition-delay: 0.7s;
}

.cimgio[data-delay="800"] {
 -webkit-transition-delay: 0.8s;
 -o-transition-delay: 0.8s;
 transition-delay: 0.8s;
}

.cimgio[data-delay="900"] {
 -webkit-transition-delay: 0.9s;
 -o-transition-delay: 0.9s;
 transition-delay: 0.9s;
}

.cimgio[data-delay="1s"] {
 -webkit-transition-delay: 1s;
 -o-transition-delay: 1s;
 transition-delay: 1s;
}

.title span {
 font-weight: bold;
}

.heading-title span {
 font-weight: bold;
}
.home-1 {
 overflow: hidden;
}
