@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?2gatwr');
  src:  url('fonts/icomoon.eot?2gatwr#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?2gatwr') format('truetype'),
    url('fonts/icomoon.woff?2gatwr') format('woff'),
    url('fonts/icomoon.svg?2gatwr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-top:before {
  content: "\e90a";
  color: #fff;
}
.icon-arrow-left:before {
  content: "\e90b";
  color: #fff;
}
.icon-arrow-right:before {
  content: "\e90c";
  color: #fff;
}
.icon-collec .path1:before {
  content: "\e90d";
  color: rgb(222, 199, 255);
}
.icon-collec .path2:before {
  content: "\e90e";
  margin-left: -1.111328125em;
  color: rgb(92, 39, 254);
}
.icon-collec .path3:before {
  content: "\e90f";
  margin-left: -1.111328125em;
  color: rgb(222, 199, 255);
}
.icon-collec .path4:before {
  content: "\e910";
  margin-left: -1.111328125em;
  color: rgb(92, 39, 254);
}
.icon-collec .path5:before {
  content: "\e911";
  margin-left: -1.111328125em;
  color: rgb(92, 39, 254);
}
.icon-collec .path6:before {
  content: "\e912";
  margin-left: -1.111328125em;
  color: rgb(222, 199, 255);
}
.icon-collec .path7:before {
  content: "\e913";
  margin-left: -1.111328125em;
  color: rgb(222, 199, 255);
}
.icon-collec .path8:before {
  content: "\e914";
  margin-left: -1.111328125em;
  color: rgb(92, 39, 254);
}
.icon-collec .path9:before {
  content: "\e915";
  margin-left: -1.111328125em;
  color: rgb(92, 39, 254);
}
.icon-collec .path10:before {
  content: "\e916";
  margin-left: -1.111328125em;
  color: rgb(222, 199, 255);
}
.icon-collec .path11:before {
  content: "\e917";
  margin-left: -1.111328125em;
  color: rgb(92, 39, 254);
}
.icon-collec .path12:before {
  content: "\e918";
  margin-left: -1.111328125em;
  color: rgb(222, 199, 255);
}
.icon-collec .path13:before {
  content: "\e919";
  margin-left: -1.111328125em;
  color: rgb(92, 39, 254);
}
.icon-collec .path14:before {
  content: "\e91a";
  margin-left: -1.111328125em;
  color: rgb(222, 199, 255);
}
.icon-collec .path15:before {
  content: "\e91b";
  margin-left: -1.111328125em;
  color: rgb(222, 199, 255);
}
.icon-collec .path16:before {
  content: "\e91c";
  margin-left: -1.111328125em;
  color: rgb(92, 39, 254);
}
.icon-collec .path17:before {
  content: "\e91d";
  margin-left: -1.111328125em;
  color: rgb(92, 39, 254);
}
.icon-collec .path18:before {
  content: "\e91e";
  margin-left: -1.111328125em;
  color: rgb(92, 39, 254);
}
.icon-collec .path19:before {
  content: "\e91f";
  margin-left: -1.111328125em;
  color: rgb(0, 227, 255);
}
.icon-collec .path20:before {
  content: "\e920";
  margin-left: -1.111328125em;
  color: rgb(0, 227, 255);
}
.icon-collec .path21:before {
  content: "\e921";
  margin-left: -1.111328125em;
  color: rgb(0, 227, 255);
}
.icon-collec .path22:before {
  content: "\e922";
  margin-left: -1.111328125em;
  color: rgb(0, 227, 255);
}
.icon-collec .path23:before {
  content: "\e923";
  margin-left: -1.111328125em;
  color: rgb(0, 227, 255);
}
.icon-collec .path24:before {
  content: "\e924";
  margin-left: -1.111328125em;
  color: rgb(0, 227, 255);
}
.icon-collec .path25:before {
  content: "\e925";
  margin-left: -1.111328125em;
  color: rgb(0, 227, 255);
}
.icon-collec .path26:before {
  content: "\e926";
  margin-left: -1.111328125em;
  color: rgb(0, 227, 255);
}
.icon-collec .path27:before {
  content: "\e927";
  margin-left: -1.111328125em;
  color: rgb(0, 227, 255);
}
.icon-collection:before {
  content: "\e928";
}
.icon-community:before {
  content: "\e929";
}
.icon-date:before {
  content: "\e92a";
}
.icon-facebook:before {
  content: "\e92b";
  color: #fff;
}
.icon-image:before {
  content: "\e92c";
}
.icon-linkedin:before {
  content: "\e92d";
  color: #fff;
}
.icon-nft .path1:before {
  content: "\e92e";
  color: rgb(0, 227, 255);
}
.icon-nft .path2:before {
  content: "\e92f";
  margin-left: -0.9501953125em;
  color: rgb(0, 208, 255);
}
.icon-nft .path3:before {
  content: "\e930";
  margin-left: -0.9501953125em;
  color: rgb(134, 95, 255);
}
.icon-nft .path4:before {
  content: "\e931";
  margin-left: -0.9501953125em;
  color: rgb(92, 39, 254);
}
.icon-nft .path5:before {
  content: "\e932";
  margin-left: -0.9501953125em;
  color: rgb(7, 10, 41);
}
.icon-nft .path6:before {
  content: "\e933";
  margin-left: -0.9501953125em;
  color: rgb(7, 10, 41);
}
.icon-nft .path7:before {
  content: "\e934";
  margin-left: -0.9501953125em;
  color: rgb(7, 10, 41);
}
.icon-nft .path8:before {
  content: "\e935";
  margin-left: -0.9501953125em;
  color: rgb(7, 10, 41);
}
.icon-nft .path9:before {
  content: "\e936";
  margin-left: -0.9501953125em;
  color: rgb(222, 199, 255);
}
.icon-nft .path10:before {
  content: "\e937";
  margin-left: -0.9501953125em;
  color: rgb(252, 168, 99);
}
.icon-nft .path11:before {
  content: "\e938";
  margin-left: -0.9501953125em;
  color: rgb(121, 134, 232);
}
.icon-nft .path12:before {
  content: "\e939";
  margin-left: -0.9501953125em;
  color: rgb(76, 92, 225);
}
.icon-plan:before {
  content: "\e93a";
  color: #fff;
}
.icon-quality:before {
  content: "\e93b";
}
.icon-resource:before {
  content: "\e93c";
}
.icon-search:before {
  content: "\e93d";
}
.icon-trading:before {
  content: "\e93e";
}
.icon-twiter:before {
  content: "\e93f";
  color: #fff;
}
.icon-users:before {
  content: "\e940";
}
.icon-wallet .path1:before {
  content: "\e941";
  color: rgb(29, 232, 241);
}
.icon-wallet .path2:before {
  content: "\e942";
  margin-left: -0.9501953125em;
  color: rgb(0, 208, 255);
}
.icon-wallet .path3:before {
  content: "\e943";
  margin-left: -0.9501953125em;
  color: rgb(0, 187, 255);
}
.icon-wallet .path4:before {
  content: "\e944";
  margin-left: -0.9501953125em;
  color: rgb(185, 162, 255);
}
.icon-wallet .path5:before {
  content: "\e945";
  margin-left: -0.9501953125em;
  color: rgb(134, 95, 255);
}
.icon-wallet .path6:before {
  content: "\e946";
  margin-left: -0.9501953125em;
  color: rgb(92, 39, 254);
}
.icon-wallet .path7:before {
  content: "\e947";
  margin-left: -0.9501953125em;
  color: rgb(121, 134, 232);
}
.icon-wallet .path8:before {
  content: "\e948";
  margin-left: -0.9501953125em;
  color: rgb(110, 119, 229);
}
.icon-wallet .path9:before {
  content: "\e949";
  margin-left: -0.9501953125em;
  color: rgb(76, 92, 225);
}
.icon-youtobe:before {
  content: "\e94a";
  color: #fff;
}
.icon-plus:before {
  content: "\e900";
  color: #fff;
}
.icon-logo-01:before {
  content: "\e909";
  color: #fff;
}
.icon-logo-02:before {
  content: "\e901";
  color: #fff;
}
.icon-logo-03:before {
  content: "\e902";
  color: #fff;
}
.icon-logo-04:before {
  content: "\e903";
  color: #fff;
}
.icon-logo-05 .path1:before {
  content: "\e904";
  color: rgb(255, 255, 255);
}
.icon-logo-05 .path2:before {
  content: "\e905";
  margin-left: -0.97265625em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.icon-logo-05 .path3:before {
  content: "\e906";
  margin-left: -0.97265625em;
  color: rgb(255, 255, 255);
}
.icon-logo-05 .path4:before {
  content: "\e907";
  margin-left: -0.97265625em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.icon-logo-06:before {
  content: "\e908";
  color: #fff;
}
